import { authHeader } from '../_helpers';

export const accountService = {
    getAll,
    get,
    getRoles,
    post,
    put,
    remove,
    password
};

function remove(id) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'DELETE',
            headers: token,
            body: JSON.stringify({id})
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/accounts/${id}`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
                return data;
            });
        });
    })
}

function post(name, email, role, password) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'POST',
            headers: token,
            body: JSON.stringify({name, email, role, password})
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/accounts`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                return data;
            });
        });
    })
}

function put(name, email, role, id) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'PUT',
            headers: token,
            body: JSON.stringify({name, email, role, id})
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/accounts`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                return data;
            });
        });
    })
}

function getAll() {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'GET',
            headers: token
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/accounts`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                return data;
            });
        });
    })
}

function get(id) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'GET',
            headers: token
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/accounts/${id}`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
                return data;
            });
        });
    })
}

function password(password) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'POST',
            headers: token,
            body: JSON.stringify({password})
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/password`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                return data;
            });
        });
    })
}

function getRoles() {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'GET',
            headers: token
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/roles`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
                return data;
            });
        });
    })
}