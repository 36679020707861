<template>
    <div id="inner" class="extra-space">
        <div class="row">
            <div class="col-12">
                <h1>
                    Lopende Dossiers
                </h1>
            </div>
        </div>

        <div class="row">
            <div class="col-12 spacer">
                &nbsp;
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <form class="form-inline">
                    <div class="form-group mb-2">
                        <label for="staticEmail2" class="sr-only">Label</label>
                        <select
                                id="filter_label"
                                class="form-control filter"
                                v-model="filter.label"
                                v-on:change="doFilter"
                        >
                            <option value="">Alle Labels</option>
                            <option
                                    v-for="item in labels"
                                    :key="item.id"
                                    v-bind:value="item.id"
                            >
                                {{item.name}}
                            </option>
                        </select>
                    </div>
                    <!-- <div class="form-group mx-sm-3 mb-2">
                         <label for="filter_rent" class="sr-only">Maximale Huur</label>
                         <select
                                 id="filter_rent"
                                 class="form-control filter"
                                 v-model="filter.rent"
                                 v-on:change="doFilter"
                         >
                             <option value="">Huur: Alles</option>
                         </select>
                     </div>-->
                    <div class="form-group mx-sm-3 mb-2">
                        <label for="filter_profilefields" class="sr-only">Extra Vereisten</label>
                        <select
                                id="filter_profilefields"
                                class="form-control filter"
                                v-model="filter.profilefields"
                                v-on:change="doFilter"
                        >
                            <option value="">Geen Extra Vereisten</option>
                            <option
                                    v-for="item in profilefields"
                                    :key="item.id"
                                    v-bind:value="item.id"
                            >
                                {{item.name}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                        <label for="filter_rooms" class="sr-only">Adres</label>
                        <input type="text" v-model="filter.address" class="form-control" placeholder="Adres" v-on:keyup="doFilter">
                    </div>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                Dossier
                            </th>
                            <th>
                                Label
                            </th>
                            <th>
                                Adres
                            </th>
                            <th>
                                Datum
                            </th>
                            <th class="text-center">
                                &nbsp;
                            </th>
                        </tr>
                    </thead>
                        <tbody>
                        <tr v-for="item in items" :key="item.id">
                            <td>
                                {{item.dossier}}
                            </td>
                            <td>
                                {{item.label}}
                            </td>
                            <td>
                                {{item.address}}
                            </td>
                            <td>
                                {{item.regdate}}
                            </td>
                            <td class="text-right">
                                <a :href="'/claims/'+item.id">
                                    <img class="icon-small" src="../assets/icons/chevron-right.svg">
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="100%"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>
 
<script>
    import {claimService} from "@/_services";
    import {settingsLabelService} from "@/_services/settings.labels.service";
    import {settingsProfilefieldService} from "@/_services/settings.profilefields.service";

    export default {
        name: 'ClaimsView',
        data: () => {
            return {
                items: [],
                deleteInfo: {
                    name: '',
                    id: ''
                },
                filter: {
                    label: '',
                    rooms: 0,
                    rent: '',
                    profilefields: '',
                    address: ''
                },
                labels: [],
                profilefields: []
            }
        },
        methods: {
            async loadFilter() {
                settingsLabelService.getAll().then((data)=> {
                    this.labels = data;
                });
                settingsProfilefieldService.getAll().then((data)=> {
                    this.profilefields = data;
                });
            },
            async loadClaims() {
                claimService.getAll(
                    this.filter.label,
                    this.filter.address,
                    this.filter.rent,
                    this.filter.profilefields
                ).then((data) => {
                    this.items = data;
                })
            },
            async doFilter() {
                this.loadClaims();
            }

        },
        async mounted() {
            this.loadClaims();
            this.loadFilter();
        }
    }
</script>

<style scoped>

</style>
