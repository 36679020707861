<template>
    <div id="inner" class="extra-space">
        <div class="row">
            <div class="col-12">
                <h1>
                    Accounts
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 spacer">
                &nbsp;
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                Naam
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                Rol
                            </th>
                            <th>
                                &nbsp;
                            </th>
                            <th class="text-center">
                                <a href="/accounts/new">
                                    <img src="../assets/icons/plus.svg">
                                </a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" :key="item.id">
                            <td>
                                {{item.name}}
                            </td>
                            <td>
                                {{item.email}}
                            </td>
                            <td>
                                {{item.role.name.split(' ')[1]}}
                            </td>
                            <td class="text-right">
                                <a :href="'/accounts/'+item.id">
                                    <img class="icon-small" src="../assets/icons/chevron-right.svg">
                                </a>
                            </td>
                            <td class="text-center">
                                <a v-on:click="openDeleteModal(item.id, item.name)" v-if="item.id !== $store.state.authentication.status.id">
                                    <img class="icon-small" src="../assets/icons/delete.svg">
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="100%"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <b-modal
                id="delete_modal"
                title="Account Verwijderen"
                @ok="deleteContact"
        >
            <p>
                U staat op het punt om de volgende gebruiker te verwijderen:
            </p>
            <p>
                {{deleteInfo.name}}
            </p>
            <p>
                Weet u dit zeker?
            </p>
        </b-modal>
    </div>
</template>

<script>
    import {accountService} from "@/_services";

    export default {
        name: 'AccountView',
        data: () => {
            return {
                items: [],
                deleteInfo: {
                    name: '',
                    id: ''
                }
            }
        },
        methods: {
            async loadAccounts() {
                accountService.getAll().then((data) => {
                    this.items = data;
                })
            },
            openDeleteModal(id,name) {
                this.deleteInfo = {
                    name: name,
                    id: id
                };
                this.$bvModal.show('delete_modal');
            },
            deleteContact() {
                accountService.remove(this.deleteInfo.id).then(() => {
                    this.loadAccounts();
                })
            }
        },
        async mounted() {
            this.loadAccounts();
        }
    }
</script>

<style scoped>

</style>
