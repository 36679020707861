<template>
    <div id="inner" class="extra-space">
        <div v-if="!finished">
            <h1 class="text-center">
                Bezig met laden
            </h1>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-11">
                    <h1>
                        {{header}}
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-11 spacer">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-11">
                    <b-form @submit.prevent="handleSubmitA">
                        <fieldset>
                            <legend>
                                <h3>
                                    <button v-bind:class="{ 'btn-form': true, 'done': state.A.done, 'active': state.A.show }">1</button>
                                    NAW Controle
                                </h3>
                            </legend>
                        </fieldset>
                    </b-form>
                    <div class="row">
                        <div class="col-12">
                            <hr/>
                        </div>
                    </div>
                    <b-form @submit.prevent="handleSubmitB">
                        <fieldset>
                            <legend>
                                <h3>
                                    <button v-bind:class="{ 'btn-form': true, 'done': state.B.done, 'active': state.B.show }">2</button>
                                    Woonmatch controle
                                </h3>
                            </legend>
                        </fieldset>
                    </b-form>
                    <div class="row">
                        <div class="col-12">
                            <hr/>
                        </div>
                    </div>
                    <b-form @submit.prevent="handleSubmitC">
                        <fieldset>
                            <legend>
                                <h3>
                                    <button v-bind:class="{ 'btn-form': true, 'done': state.C.done, 'active': state.C.show }">3</button>
                                    Gegevens over de urgent
                                    <a class="align-self-center float-right" v-if="!state.C.show" v-on:click="openBlock('C')">
                                        Wijzig
                                    </a>
                                </h3>
                            </legend>
                            <div v-if="state.C.show && isFinished === 0">
                                <div class="row">
                                    <b-form-group
                                            id="letters-group"
                                            label="Voorletters"
                                            label-for="letters"
                                            class="col-3"
                                    >
                                        <b-form-input
                                                id="letters"
                                                v-model="form.letters"
                                                type="text"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            id="lastname-group"
                                            label="Achternaam"
                                            label-for="lastname"
                                            class="col-7"
                                    >
                                        <b-form-input
                                                id="lastname"
                                                v-model="form.lastname"
                                                type="text"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="zipcode-group"
                                            label="Postcode"
                                            label-for="zipcode"
                                            class="col-2"
                                    >
                                        <b-form-input
                                                id="zipcode"
                                                type="text"
                                                v-model="form.zipcode"
                                                min="6"
                                                max="6"
                                        />
                                    </b-form-group>
                                    <b-form-group
                                            id="housenumber-group"
                                            label="Huisnummer"
                                            label-for="housenumber"
                                            class="col-2"
                                    >
                                        <b-form-input
                                                id="housenumber"
                                                type="text"
                                                v-model="form.housenumber"
                                        />
                                    </b-form-group>
                                    <b-form-group
                                            id="dob-group"
                                            label="Geboortedatum*"
                                            label-for="dob"
                                            class="col-3"
                                    >
                                        <b-form-input
                                                id="dob"
                                                type="date"
                                                v-model="form.dob"
                                                :max="settings.maxdate"
                                                required/>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="email-group"
                                            label="E-mail adres*"
                                            label-for="email"
                                            class="col-6"
                                    >
                                        <b-form-input
                                                id="email"
                                                v-model="form.email"
                                                type="email"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            id="phone-group"
                                            label="Telefoonnummer"
                                            label-for="phone"
                                            class="col-4"
                                    >
                                        <b-form-input
                                                id="phone"
                                                v-model="form.phone"
                                                type="text"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="adults-group"
                                            label="Volwassenen*"
                                            label-for="adults"
                                            class="col-2"
                                    >
                                        <b-form-input
                                                id="adults"
                                                v-model="form.adults"
                                                type="number"
                                                min="1"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            id="children-group"
                                            label="Kinderen*"
                                            label-for="children"
                                            class="col-2"
                                    >
                                        <b-form-input
                                                id="children"
                                                v-model="form.children"
                                                type="number"
                                                min="0"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            id="income-group"
                                            label="Gezinsinkomen*"
                                            label-for="income"
                                            class="col-5"
                                    >
                                        <b-form-input
                                                id="income"
                                                v-model="form.income"
                                                type="number"
                                                min="0"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </fieldset>
                    </b-form>
                    <div class="row">
                        <div class="col-12">
                            <hr/>
                        </div>
                    </div>
                    <b-form @submit.prevent="handleSubmitD">
                        <fieldset>
                            <legend>
                                <h3>
                                    <button v-bind:class="{ 'btn-form': true, 'done': state.D.done, 'active': state.D.show }">4</button>
                                    Zoekprofiel
                                    <a class="align-self-center float-right" v-if="!state.D.show" v-on:click="openBlock('D')">
                                        Wijzig
                                    </a>
                                </h3>
                            </legend>
                            <div v-if="state.D.show">
                                <div class="row">
                                    <b-form-group
                                            id="dossier-group"
                                            label="Dossiercode*"
                                            label-for="dossier"
                                            class="col-5"
                                    >
                                        <b-form-input
                                                id="dossier"
                                                v-model="form.dossier"
                                                type="text"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            id="dob-group"
                                            label="Datum urgentie toegewezen"
                                            label-for="regdate"
                                            class="col-3"
                                    >
                                        <b-form-input
                                                id="regdate"
                                                type="date"
                                                v-bind:value="form.regdate"
                                                disabled/>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="label-group"
                                            label="Urgentielabel"
                                            label-for="label"
                                            class="col-6"
                                    >
                                        <b-form-select
                                                v-model="form.label"
                                                required>
                                            <b-form-select-option v-for="item in settings.labels" :key="item.id" :value="item.id">
                                                {{item.name}}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                    <b-form-group
                                            id="mega-group"
                                            label="Mega Urgent?"
                                            label-for="mega"
                                            class="col-2"
                                    >
                                        <b-form-select
                                                v-model="form.mega"
                                                required>
                                            <b-form-select-option :value="true">Ja</b-form-select-option>
                                            <b-form-select-option :value="false">Nee</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="neighborhood-group"
                                            label="Welke wijken niet?"
                                            label-for="neighborhood"
                                            class="col-8"
                                    >
                                        <b-form-input
                                                id="neighborhood"
                                                v-model="form.neighborhood"
                                                type="text">
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="notes-group"
                                            label="Afkomstig van"
                                            label-for="notes"
                                            class="col-8"
                                    >
                                        <select
                                                id="notes"
                                                class="form-control"
                                                v-model="form.notes"
                                        >
                                            <option value=""></option>
                                            <option value="Leviaan">Leviaan</option>
                                            <option value="AOP">AOP</option>
                                            <option value="Casa24">Casa24</option>
                                            <option value="Prinsenstichting">Prinsenstichting</option>
                                            <option value="Odion">Odion</option>
                                            <option value="Dijk&amp;Duin">Dijk&Duin</option>
                                            <option value="Palier">Palier</option>
                                        </select>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="rent-group"
                                            label="Maximale Woonlasten"
                                            label-for="rent"
                                            class="col-5"
                                    >
                                        <b-form-input
                                                id="rent"
                                                v-bind:value="form.maxrent + '  (Passend Toewijzen)'"
                                                type="text"
                                                disabled>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            id="rooms-group"
                                            label="Minimaal aantal kamers"
                                            label-for="rooms"
                                            class="col-3"
                                    >
                                        <b-form-input
                                                id="rooms"
                                                v-model="form.rooms"
                                                type="number"
                                                min="0"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label>
                                            Vereisten
                                        </label>
                                    </div>
                                    <div class="col-6" v-for="item in settings.specials" :key="item.id">
                                        <label class="toggle-switch">
                                            <input type="checkbox" name="specials" v-model="form.profilefields" :value="item.id">
                                            <span class="slider"></span>
                                        </label>
                                        <span class="toggle-label">{{item.name}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 align-self-end">
                                        <b-button class="float-right align-self-end" v-if="!isFinished" type="submit" variant="primary">Urgent Opslaan</b-button>
                                        <b-button class="float-right align-self-end" v-if="inRefusal" v-on:click="releaseRefusal" variant="success">Weigering Accepteren</b-button>
                                        <b-button class="float-right align-self-end" v-if="inRefusal" v-on:click="rejectRefusal" variant="danger">Urgent Uitschrijven</b-button>
                                        <b-button class="float-right align-self-end disabled" v-else variant="primary">Urgent is Afgehandeld</b-button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </b-form>
                    <div class="row">
                        <div class="col-12">
                            <hr/>
                        </div>
                    </div>
                    <div>
                        <fieldset>
                            <legend>
                                <h3>
                                    <button class="btn-form done">5</button>
                                    Geschiedenis
                                </h3>
                            </legend>
                            <div class="row">
                                <div class="col-12">
                                    <ul>
                                        <li v-if="settings.history.length === 0">
                                            Deze urgent is nog niet in behandeling genomen.
                                        </li>
                                        <li v-else v-for="item in settings.history" :key="item.regdate">
                                            {{item.type}} door {{item.corporation.length === 0 ? 'Gemeente' : item.corporation}} op {{item.regdate}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="row">
                        <br/>
                    </div>
                    <div class="row">
                        <div class="col-12 align-self-end">
                            <b-button class="float-right align-self-end" v-on:click="deleteModal" variant="danger">Urgent Verwijderen</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal
                    title-html="Urgent Verwijderen"
                    id="deleteConfirm"
                    size="lg"
                    ok-title="Verwijderen bevestigen"
                    ok-variant="danger"
                    @ok="deleteUrgent"
                    cancel-title="Nee, ga terug"
                    cancel-variant="success">
                <b-row>
                    <b-col>
                        <p>
                            Weet u zeker dat u dit dossier wilt verwijderen?
                        </p>
                    </b-col>
                </b-row>
            </b-modal>
    </div>
</template>

<script>
    import {urgentsService, claimService} from "@/_services";
    import validator from "validator/es";

    export default {
        name: 'AccountView',
        data: () => {
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const maxDate = new Date(today).setFullYear(today.getFullYear() - 18);

            return {
                finished: false,
                isFinished: false,
                header: 'Woningzoekende ophalen',
                inRefusal: 0,
                settings: {
                    maxdate: new Date(maxDate).toISOString().split('T')[0],
                    checkresults: [],
                    newperson: {
                        dob: '',
                        lastname: '',
                        accountnumber: '',
                        letters: ''
                    },
                    labels: [],
                    specials: [],
                    history: []
                },
                state: {
                    A: {
                        show: 0,
                        done: 1
                    },
                    B: {
                        show: 0,
                        done: 1
                    },
                    C: {
                        show: 1,
                        done: 1
                    },
                    D: {
                        show: 1,
                        done: 1
                    }
                },
                form: {
                    accountnumber: '',
                    selectedPerson: {},
                    email: '',
                    zipcode: '',
                    housenumber:'',
                    dob: '',
                    children: 0,
                    income: 0,
                    dossier: '',
                    adults: 1,
                    lastname: '',
                    letters: '',
                    phone: '',
                    rent: 0,
                    profilefields: [],
                    rooms: 1,
                    mega: false,
                    label: '',
                    neighborhood: '',
                    regdate: '',
                    maxrent: 0,
                    notes: ''
                }
            }
        },
        methods: {
            dateswap(date) {
                if(date === undefined) {
                    return undefined;
                }
                let arr = date.split('-');
                return arr.reverse().join('-');
            },
            loadUrgent() {
                if(!validator.isUUID(this.$route.params.id)) {
                    this.$router.push('/urgenten')
                } else {
                    urgentsService.get(this.$route.params.id).then((data) => {
                        this.getLabels();
                        this.getSpecials();
                        this.form = {
                            email: data.secure.email,
                            zipcode: data.secure.zipcode,
                            housenumber: data.secure.housenumber,
                            dob: this.dateswap(data.secure.dob),
                            children: data.children,
                            income: data.income,
                            dossier: data.dossier,
                            adults: data.adults,
                            lastname: data.secure.lastname,
                            letters: data.secure.letters,
                            phone: data.secure.phone,
                            rent: data.rent,
                            profilefields: [],
                            rooms: data.rooms,
                            mega: data.mega === 1 ? true : false,
                            label: data.label.id,
                            neighborhood: data.neighborhood,
                            maxrent: data.fitting.max,
                            regdate: this.dateswap(data.regdate),
                            accountnumber: data.accountnumber,
                            notes: data.notes
                        }
                        this.isFinished = data.finished;
                        for(let i = 0; i < data.profilefields.length; i++) {
                            this.form.profilefields.push(data.profilefields[i].id)
                        }
                        if(data.status[0] !== undefined && data.status[0].type === 'Weigering door Urgent') {
                            this.inRefusal = 1;
                        }
                        this.settings.history = data.status;
                        this.finished = 1;
                        console.log(data);
                    });

                }
            },
            handleSubmitD() {
                if(this.isFinished === 0) {
                    let naw = {
                        children: this.form.children,
                        income: this.form.income,
                        adults: this.form.adults,
                        dossier: this.form.dossier,
                        lastname: this.form.lastname,
                        zipcode: this.form.zipcode,
                        letters: this.form.letters,
                        housenumber: this.form.housenumber,
                        dob: this.form.dob,
                        phone: this.form.phone,
                        email: this.form.email
                    }
                    let profile = {
                        rent: 0,
                        profilefields: this.form.profilefields,
                        rooms: this.form.rooms,
                        mega: this.form.mega,
                        label: this.form.label,
                        neighborhood: this.form.neighborhood,
                        regdate: this.form.regdate,
                        accountnumber: this.form.accountnumber,
                        notes: this.form.notes
                    }
                    urgentsService.put(naw, profile, this.$route.params.id).then(()=>{
                        this.$router.push('/urgenten')
                    });
                }
            },
            openBlock(block) {
                Object.keys(this.state).forEach((data) => {
                    if(data !== block) this.state[data].show = 0;
                })
                this.state[block].show = 1;
            },
            async getLabels() {
                urgentsService.getLabels().then((data) =>{
                    this.settings.labels = data;
                })
            },
            async getSpecials() {
                urgentsService.getFields().then((data) =>{
                    this.settings.specials = data;
                })
            },
            releaseRefusal() {
                claimService.release(this.$route.params.id).then(() =>{
                    this.$router.push('/urgenten')
                });
            },
            rejectRefusal() {
                claimService.confirm(this.$route.params.id).then(() =>{
                    this.$router.push('/urgenten')
                });
            },
            deleteModal() {
              this.$bvModal.show('deleteConfirm')
            },
            deleteUrgent() {
              urgentsService.del(this.$route.params.id).then(() =>{
                this.$router.push('/urgenten')
              });
            }
        },
        async mounted() {
            this.loadUrgent();
        }
    }

</script>

<style scoped>

</style>
