<template>
    <div id="inner" class="extra-space">
        <div v-if="!finished">
            <h1 class="text-center">
                Bezig met laden
            </h1>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-12">
                    <h1>
                        Profiel veld {{status}}
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12 spacer">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b-form @submit.prevent="handleSubmit">
                        <b-form-group
                            id="name-group"
                            label="Naam"
                            label-for="name">
                            <b-form-input
                                id="name"
                                v-model="form.name"
                                type="text"
                                required/>
                        </b-form-group>
                        <b-button class="float-right" type="submit" variant="primary">Opslaan</b-button>
                    </b-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import validator from "validator/es";
    import {settingsProfilefieldService} from "@/_services/settings.profilefields.service";

    export default {
        name: 'SettingsProfilefieldsEdit',
        data: () => {
            return {
                finished: false,
                status: 'Aanpassen',
                isNew: 0,
                form: {
                    name: '',

                }
            }
        },
        methods: {
            handleSubmit() {
                if(this.isNew) {
                    settingsProfilefieldService.post(this.form.name).then(() => {
                        this.$router.push('/settings');
                    });
                } else {
                    settingsProfilefieldService.put(this.form.name, this.$route.params.id).then(() => {
                        this.$router.push('/settings');
                    });
                }
            },
            async loadAccount() {
                if(!validator.isUUID(this.$route.params.id)) {
                    if(this.$route.params.id === 'new') {
                        this.status = 'Aanmaken';
                        this.isNew = 1;
                        this.finished = 1;
                    } else {
                        this.$router.push('/settings')
                    }
                } else {
                    settingsProfilefieldService.get(this.$route.params.id).then((data) => {
                        if(data.id.length === 0) {
                            this.$router.push('/settings')
                        } else {
                            this.form.name = data.name;
                            this.finished = true;
                        }
                    })
                }


            }
        },
        async mounted() {
            await this.loadAccount();
        }
    }
</script>

<style scoped>

</style>
