<template>
    <div id="inner" class="extra-space">
        <div class="row">
            <div class="col-12">
                <h1>
                    Instellingen
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 spacer">
                <h2>
                    <hr/>
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>
                    Labels
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                Naam
                            </th>
                            <th class="text-center">
                                &nbsp;
                            </th>
                            <th class="text-center">
                                <a href="/settings/labels/new">
                                    <img src="../assets/icons/plus.svg">
                                </a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in labels" :key="item.id">
                            <td>
                                {{item.name}}
                            </td>
                            <td class="text-right">
                                <a :href="'/settings/labels/'+item.id">
                                    <img class="icon-small" src="../assets/icons/chevron-right.svg">
                                </a>
                            </td>
                            <td class="text-center">
                                <a v-on:click="openDeleteModalLabels(item.id, item.name)">
                                    <img class="icon-small" src="../assets/icons/delete.svg">
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="100%"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col-12 spacer">
                <h2>
                    <hr/>
                </h2>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h2>
                    Profiel velden
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                Naam
                            </th>
                            <th class="text-center">
                                &nbsp;
                            </th>
                            <th class="text-center">
                                <a href="/settings/profilefields/new">
                                    <img src="../assets/icons/plus.svg">
                                </a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in profilefields" :key="item.id">
                            <td>
                                {{item.name}}
                            </td>
                            <td class="text-right">
                                <a :href="'/settings/profilefields/'+item.id">
                                    <img class="icon-small" src="../assets/icons/chevron-right.svg">
                                </a>
                            </td>
                            <td class="text-center">
                                <a v-on:click="openDeleteModalProfilefields(item.id, item.name)">
                                    <img class="icon-small" src="../assets/icons/delete.svg">
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="100%"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        
        <b-modal
                id="delete_modal_labels"
                title="Label Verwijderen"
                @ok="deleteLabel"
        >
            <p>
                U staat op het punt om het volgende label te verwijderen:
            </p>
            <p>
                {{deleteInfo.name}}
            </p>
            <p>
                Weet u dit zeker?
            </p>
        </b-modal>
        <b-modal
                id="delete_modal_profilefields"
                title="profilefield Verwijderen"
                @ok="deleteProfilefield"
        >
            <p>
                U staat op het punt om het volgende profiel onderdeel te verwijderen:
            </p>
            <p>
                {{deleteInfo.name}}
            </p>
            <p>
                Weet u dit zeker?
            </p>
        </b-modal>
    </div>
</template>

<script>

    import {settingsLabelService} from "@/_services/settings.labels.service";
    import {settingsProfilefieldService} from "@/_services/settings.profilefields.service";

    export default {
        name: 'settingsView',
        data: () => {
            return {
                labels: [],
                profilefields: [],
                deleteInfo: {
                    name: '',
                    id: ''
                }
            }
        },
        methods: {
            async loadTables() {
                settingsLabelService.getAll().then((data)=> {
                    this.labels = data;
                });
                settingsProfilefieldService.getAll().then((data)=> {
                    this.profilefields = data;
                });
            },
            openDeleteModalLabels(id,name) {
                this.deleteInfo = {
                    name: name,
                    id: id
                };
                this.$bvModal.show('delete_modal_labels');
            },
            deleteLabel() {
                settingsLabelService.remove(this.deleteInfo.id).then(() => {
                    this.loadTables();
                })
            },
            openDeleteModalProfilefields(id,name) {
                this.deleteInfo = {
                    name: name,
                    id: id
                };
                this.$bvModal.show('delete_modal_profilefields');
            },
            deleteProfilefield() {
                settingsProfilefieldService.remove(this.deleteInfo.id).then(() => {
                    this.loadTables();
                })
            }
        },
        async mounted() {
            this.loadTables();
        }
    }
</script>

<style scoped>

</style>
