import { authHeader } from '../_helpers';

export const claimService = {
    make,
    getAll,
    finish,
    rescind,
    refuse,
    release,
    confirm
};

function make(id, address, address_id) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'POST',
            headers: token,
            body: JSON.stringify({id, address, address_id})
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/claim/${id}`, requestOptions).then(function(response){
            if(response.status === 400) {
                return false;
            } else {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    return data;
                });
            }
        });
    })
}
function finish(id) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'POST',
            headers: token,
            body: JSON.stringify({id})
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/claim/${id}/finish`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                return data;
            });
        });
    })
}

function rescind(id) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'POST',
            headers: token,
            body: JSON.stringify({id})
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/claim/${id}/reject/corporation`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                return data;
            });
        });
    })
}
function confirm(id) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'POST',
            headers: token,
            body: JSON.stringify({id})
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/claim/${id}/reject/municipality`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                return data;
            });
        });
    })
}
function release(id) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'POST',
            headers: token,
            body: JSON.stringify({id})
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/claim/${id}/reject/release`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                return data;
            });
        });
    })
}
function refuse(id) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'POST',
            headers: token,
            body: JSON.stringify({id})
        };

        return fetch(`https://${process.env.VUE_APP_API_URL}/claim/${id}/reject/urgent`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                return data;
            });
        });
    })
}

function getAll(label, address, rent, profilefields) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'GET',
            headers: token
        };

        let url = new URL(`https://${process.env.VUE_APP_API_URL}/claim`);
        url.search = new URLSearchParams({label, address, rent, profilefields});

        return fetch(url, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                return data;
            });
        });
    })
}