<template>
    <div id="inner" class="extra-space">
        <div class="row">
            <div class="col-12">
                <h1>
                    Rapportages
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 spacer">
                &nbsp;
            </div>
        </div>
        <div class="row">
            <div class="col-12">
              <p>Toewijzingen per corporatie</p>
              <template>
                <BarChartAssigned ref="bar"/>
              </template>
              <br/>
              <p>Toewijzingen per soort urgentie</p>
              <template>
                <BarChartLabels ref="bar"/>
              </template>
              <br/>
              <p>Toewijzingen per oorsprong urgente</p>
              <template>
                <BarChartSource ref="bar"/>
              </template>
            </div>
        </div>
    </div>
</template>

<script>
    /*import {reportsService} from "@/_services";*/
    import BarChartLabels from '/src/components/BarChartLabels'
    import BarChartSource from '/src/components/BarChartSource'
    import BarChartAssigned from '/src/components/BarChartAssigned'

    export default {
      name: 'App',
      components: { BarChartLabels,BarChartSource,BarChartAssigned }
    }

    /*export default {
        name: 'ReportsView',
        data: () => {
            return {
                items: [],
                deleteInfo: {
                    name: '',
                    id: ''
                }
            }
        },
        methods: {
            async loadReports() {
                reportsService.getAll().then((data) => {
                    this.items = data;
                    console.log(this.items);
                })
            }
        },
        async mounted() {
            this.loadReports();
        }
    }*/
</script>

<style scoped>

</style>
