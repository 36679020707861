import {userService} from "@/_services";

export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return userService.refresh().then(function(data){
            return { 'Authorization': 'Bearer ' + data.token, 'Content-Type': 'application/json' };
        });
    } else {
        return {};
    }
}