<template>
    <div id="inner" class="extra-space">
        <div v-if="!finished">
            <h1 class="text-center">
                Bezig met laden
            </h1>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-11">
                    <h1>
                        {{header}}
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-11 spacer">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-11">
                    <b-form @submit.prevent="handleSubmitA">
                        <fieldset>
                            <legend>
                                <h3>
                                    <button v-bind:class="{ 'btn-form': true, 'done': state.A.done, 'active': state.A.show }">1</button>
                                    NAW Controle
                                    <a class="align-self-center float-right" v-if="!state.A.show" v-on:click="openBlock('A')">
                                        Wijzig
                                    </a>
                                </h3>
                            </legend>
                            <div v-if="state.A.show">
                                <div class="row">
                                    <b-form-group
                                            id="email-group"
                                            label="E-mail adres*"
                                            label-for="email"
                                            class="col-6"
                                    >
                                        <b-form-input
                                                id="email"
                                                v-model="form.email"
                                                type="email"
                                                required>
                                        </b-form-input>
                                    </b-form-group>

                                    <b-form-group
                                        id="dob-group"
                                        label="Geboortedatum*"
                                        label-for="dob"
                                        class="col-3"
                                    >
                                        <b-form-input
                                            id="dob"
                                            type="date"
                                            v-model="form.dob"
                                            :max="settings.maxdate"
                                            required/>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="zipcode-group"
                                            label="Postcode"
                                            label-for="zipcode"
                                            class="col-2"
                                    >
                                        <b-form-input
                                                id="zipcode"
                                                type="text"
                                                v-model="form.zipcode"
                                                min="6"
                                                max="6"/>
                                    </b-form-group>
                                    <b-form-group
                                            id="housenumber-group"
                                            label="Huisnummer"
                                            label-for="housenumber"
                                            class="col-2"
                                    >
                                        <b-form-input
                                                id="housenumber"
                                                type="text"
                                                v-model="form.housenumber"
                                                />
                                    </b-form-group>
                                    <div class="col-8 align-self-end">
                                        <b-button class="float-right  align-self-center" type="submit" variant="primary">Volgende</b-button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </b-form>
                    <div class="row">
                        <div class="col-12">
                            <hr/>
                        </div>
                    </div>
                    <b-form @submit.prevent="handleSubmitB">
                        <fieldset>
                            <legend>
                                <h3>
                                    <button v-bind:class="{ 'btn-form': true, 'done': state.B.done, 'active': state.B.show }">2</button>
                                    Woonmatch controle
                                    <a class="align-self-center float-right" v-if="!state.B.show" v-on:click="openBlock('B')">
                                        Wijzig
                                    </a>
                                </h3>
                            </legend>
                            <div v-if="state.B.show">
                                <div class="row">
                                    <div class="col-12">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Accountnummer
                                                    </th>
                                                    <th>
                                                        Voorletters
                                                    </th>
                                                    <th>
                                                        Achternaam
                                                    </th>
                                                    <th>
                                                        Geboortedatum
                                                    </th>
                                                    <th>
                                                        Kies
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in settings.checkresults" :key="item.accountnumber">
                                                    <td>
                                                        {{item.accountnumber}}
                                                    </td>
                                                    <td>
                                                        {{item.letters}}
                                                    </td>
                                                    <td>
                                                        {{item.lastname}}
                                                    </td>
                                                    <td>
                                                        {{item.dob}}
                                                    </td>
                                                    <td>
                                                        <label class="toggle-switch">
                                                            <input type="radio" name="selectedPerson" v-model="form.selectedPerson" :value="item" required>
                                                            <span class="slider"></span>
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        &nbsp;
                                                    </td>
                                                    <td>
                                                        Nieuwe
                                                    </td>
                                                    <td>
                                                        Woningzoekende
                                                    </td>
                                                    <td>
                                                        &nbsp;
                                                    </td>
                                                    <td>
                                                        <label class="toggle-switch">
                                                            <input type="radio" name="selectedPerson" v-model="form.selectedPerson" :value="{}" required>
                                                            <span class="slider"></span>
                                                        </label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <b-button class="float-right  align-self-center" type="submit" variant="primary">Volgende</b-button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </b-form>
                    <div class="row">
                        <div class="col-12">
                            <hr/>
                        </div>
                    </div>
                    <b-form @submit.prevent="handleSubmitC">
                        <fieldset>
                            <legend>
                                <h3>
                                    <button v-bind:class="{ 'btn-form': true, 'done': state.C.done, 'active': state.C.show }">3</button>
                                    Gegevens over de urgent
                                    <a class="align-self-center float-right" v-if="!state.C.show" v-on:click="openBlock('C')">
                                        Wijzig
                                    </a>
                                </h3>
                            </legend>
                            <div v-if="state.C.show">
                                <div class="row">
                                    <b-form-group
                                            id="letters-group"
                                            label="Voorletters"
                                            label-for="letters"
                                            class="col-3"
                                    >
                                        <b-form-input
                                                id="letters"
                                                v-model="form.letters"
                                                type="text"
                                                >
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            id="lastname-group"
                                            label="Achternaam"
                                            label-for="lastname"
                                            class="col-7"
                                    >
                                        <b-form-input
                                                id="lastname"
                                                v-model="form.lastname"
                                                type="text"
                                                >
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="zipcode-group"
                                            label="Postcode"
                                            label-for="zipcode"
                                            class="col-2"
                                    >
                                        <b-form-input
                                                id="zipcode"
                                                type="text"
                                                v-model="form.zipcode"
                                                min="6"
                                                max="6"
                                                />
                                    </b-form-group>
                                    <b-form-group
                                            id="housenumber-group"
                                            label="Huisnummer"
                                            label-for="housenumber"
                                            class="col-2"
                                    >
                                        <b-form-input
                                                id="housenumber"
                                                type="text"
                                                v-model="form.housenumber"
                                                />
                                    </b-form-group>
                                    <b-form-group
                                            id="dob-group"
                                            label="Geboortedatum*"
                                            label-for="dob"
                                            class="col-3"
                                    >
                                        <b-form-input
                                                id="dob"
                                                type="date"
                                                v-model="form.dob"
                                                :max="settings.maxdate"
                                                required/>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="email-group"
                                            label="E-mail adres*"
                                            label-for="email"
                                            class="col-6"
                                    >
                                        <b-form-input
                                                id="email"
                                                v-model="form.email"
                                                type="email"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            id="phone-group"
                                            label="Telefoonnummer"
                                            label-for="phone"
                                            class="col-4"
                                    >
                                        <b-form-input
                                                id="phone"
                                                v-model="form.phone"
                                                type="text"
                                                >
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="adults-group"
                                            label="Volwassenen*"
                                            label-for="adults"
                                            class="col-2"
                                    >
                                        <b-form-input
                                                id="adults"
                                                v-model="form.adults"
                                                type="number"
                                                min="1"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            id="children-group"
                                            label="Kinderen"
                                            label-for="children*"
                                            class="col-2"
                                    >
                                        <b-form-input
                                                id="children"
                                                v-model="form.children"
                                                type="number"
                                                min="0"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            id="income-group"
                                            label="Gezinsinkomen*"
                                            label-for="income"
                                            class="col-5"
                                    >
                                        <b-form-input
                                                id="income"
                                                v-model="form.income"
                                                type="number"
                                                min="0"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <div class="col-3 align-self-end">
                                        <b-button class="float-right align-self-end" type="submit" variant="primary">Volgende</b-button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </b-form>
                    <div class="row">
                        <div class="col-12">
                            <hr/>
                        </div>
                    </div>
                    <b-form @submit.prevent="handleSubmitD">
                        <fieldset>
                            <legend>
                                <h3>
                                    <button v-bind:class="{ 'btn-form': true, 'done': state.D.done, 'active': state.D.show }">4</button>
                                    Zoekprofiel
                                    <a class="align-self-center float-right" v-if="!state.D.show" v-on:click="openBlock('D')">
                                        Wijzig
                                    </a>
                                </h3>
                            </legend>
                            <div v-if="state.D.show">
                                <div class="row">
                                    <b-form-group
                                            id="dossier-group"
                                            label="Dossiercode*"
                                            label-for="dossier"
                                            class="col-4"
                                    >
                                        <b-form-input
                                                id="dossier"
                                                v-model="form.dossier"
                                                type="text"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            id="dob-group"
                                            label="Datum urgentie toegewezen*"
                                            label-for="regdate"
                                            class="col-4"
                                    >
                                        <b-form-input
                                                id="regdate"
                                                type="date"
                                                v-model="form.regdate"
                                                required/>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="label-group"
                                            label="Urgentielabel*"
                                            label-for="label"
                                            class="col-6"
                                    >
                                        <b-form-select
                                                v-model="form.label"
                                                required>
                                            <b-form-select-option v-for="item in settings.labels" :key="item.id" :value="item.id">
                                                {{item.name}}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                    <b-form-group
                                            id="mega-group"
                                            label="Mega Urgent?"
                                            label-for="mega"
                                            class="col-2"
                                    >
                                        <b-form-select
                                                v-model="form.mega"
                                                required>
                                            <b-form-select-option :value="true">Ja</b-form-select-option>
                                            <b-form-select-option :value="false">Nee</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="neighborhood-group"
                                            label="Welke wijken niet?"
                                            label-for="neighborhood"
                                            class="col-8"
                                    >
                                        <b-form-input
                                                id="neighborhood"
                                                v-model="form.neighborhood"
                                                type="text">
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="notes-group"
                                            label="Afkomst van"
                                            label-for="notes"
                                            class="col-8"
                                    >

                                        <select
                                                id="notes"
                                                class="form-control"
                                                v-model="form.notes"
                                        >
                                            <option value=""></option>
                                            <option value="Leviaan">Leviaan</option>
                                            <option value="AOP">AOP</option>
                                            <option value="Casa24">Casa24</option>
                                            <option value="Prinsenstichting">Prinsenstichting</option>
                                            <option value="Odion">Odion</option>
                                            <option value="Dijk&amp;Duin">Dijk&Duin</option>
                                            <option value="Palier">Palier</option>
                                        </select>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <b-form-group
                                            id="rent-group"
                                            label="Maximale Woonlasten"
                                            label-for="rent"
                                            class="col-5"
                                    >
                                        <b-form-input
                                                id="rent"
                                                value="Automatische Berekening"
                                                type="text"
                                                disabled>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            id="rooms-group"
                                            label="Minimaal aantal kamers"
                                            label-for="rooms"
                                            class="col-3"
                                    >
                                        <b-form-input
                                                id="rooms"
                                                v-model="form.rooms"
                                                type="number"
                                                min="0"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label>
                                            Vereisten
                                        </label>
                                    </div>
                                    <div class="col-6" v-for="item in settings.specials" :key="item.id">
                                        <label class="toggle-switch">
                                            <input type="checkbox" name="specials" v-model="form.profilefields" :value="item.id">
                                            <span class="slider"></span>
                                        </label>
                                        <span class="toggle-label">{{item.name}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 align-self-end">
                                        <b-button class="float-right align-self-end" type="submit" variant="primary">Urgent Opslaan</b-button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </b-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {urgentsService} from "@/_services";

    export default {
        name: 'urgentNew',
        data: () => {
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const maxDate = new Date(today).setFullYear(today.getFullYear() - 18);

            return {
                finished: false,
                header: 'Woningzoekende ophalen',
                settings: {
                    maxdate: new Date(maxDate).toISOString().split('T')[0],
                    checkresults: [],
                    newperson: {
                        dob: '',
                        lastname: '',
                        accountnumber: '',
                        letters: ''
                    },
                    labels: [],
                    specials: []
                },
                state: {
                    A: {
                        show: 1,
                        done: 0
                    },
                    B: {
                        show: 0,
                        done: 0
                    },
                    C: {
                        show: 0,
                        done: 0
                    },
                    D: {
                        show: 0,
                        done: 0
                    }
                },
                form: {
                    accountnumber: '',
                    selectedPerson: {},
                    email: '',
                    zipcode: '',
                    housenumber:'',
                    dob: '',
                    children: 0,
                    income: 0,
                    dossier: '',
                    adults: 1,
                    lastname: '',
                    letters: '',
                    phone: '',
                    rent: 0,
                    profilefields: [],
                    rooms: 1,
                    mega: false,
                    label: '',
                    neighborhood: '',
                    regdate: '',
                    notes: ''
                }
            }
        },
        methods: {
            handleSubmitA() {
                this.settings.newperson.dob = this.form.dob;
                urgentsService.check(
                    this.form.email,
                    this.form.dob,
                    this.form.zipcode,
                    this.form.housenumber).then((data) => {
                        this.settings.checkresults = data;
                        this.state.A.done = 1;
                        this.state.A.show = 0;
                        this.state.B.show = 1;
                })
            },
            handleSubmitB() {
                if(Object.keys(this.form.selectedPerson).length !== 0) {
                    this.form.accountnumber = this.form.selectedPerson.accountnumber;
                    this.form.email = this.form.selectedPerson.email;
                    this.form.zipcode = this.form.selectedPerson.postcode;
                    this.form.housenumber = this.form.selectedPerson.streetnumber;
                    this.form.children = this.form.selectedPerson.childeren;
                    this.form.income = this.form.selectedPerson.income;
                    this.form.adults = this.form.selectedPerson.adults;
                    this.form.lastname = this.form.selectedPerson.lastname;
                    this.form.letters = this.form.selectedPerson.letters;
                    this.form.phone = this.form.selectedPerson.phone;
                }
                this.state.B.done = 1;
                this.state.B.show = 0;
                this.state.C.show = 1;
            },
            handleSubmitC() {
                this.state.C.done = 1;
                this.state.C.show = 0;
                this.state.D.show = 1;
            },
            handleSubmitD() {
                let naw = {
                    children: this.form.children,
                    income: this.form.income,
                    adults: this.form.adults,
                    dossier: this.form.dossier,
                    lastname: this.form.lastname,
                    zipcode: this.form.zipcode,
                    letters: this.form.letters,
                    housenumber: this.form.housenumber,
                    dob: this.form.dob,
                    phone: this.form.phone,
                    email: this.form.email
                }
                let profile = {
                    accountnumber: this.form.accountnumber,
                    rent: this.form.rent,
                    profilefields: this.form.profilefields,
                    rooms: this.form.rooms,
                    mega: this.form.mega,
                    label: this.form.label,
                    neighborhood: this.form.neighborhood,
                    regdate: this.form.regdate,
                    notes: this.form.notes
                }
                urgentsService.post(naw, profile, this.form.accountnumber).then(()=>{
                    this.$router.push('/urgenten')
                });
            },
            openBlock(block) {
                Object.keys(this.state).forEach((data) => {
                    if(data !== block) this.state[data].show = 0;
                })
                this.state[block].show = 1;
            },
            async getLabels() {
                urgentsService.getLabels().then((data) =>{
                    this.settings.labels = data;
                })
            },
            async getSpecials() {
                urgentsService.getFields().then((data) =>{
                    this.settings.specials = data;
                })
            }
        },
        async mounted() {
            this.getLabels();
            this.getSpecials();
            this.finished = true;
        }
    }
</script>

<style scoped>

</style>
