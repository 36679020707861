import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '@/components/Home'
import LoginPage from '@/components/Login'
import UrgentenView from '@/components/UrgentenView'
import AccountView from '@/components/AccountView'
import AccountEdit from "@/components/AccountEdit";
import UrgentenEdit from "@/components/UrgentenEdit";
import UrgentenNew from "@/components/UrgentenNew";
import ClaimsView from "@/components/ClaimsView";
import ClaimsEdit from "@/components/ClaimsEdit";
import SSO from "@/components/SSO";
import ReportsView from "@/components/ReportsView";
import SettingsView from "@/components/SettingsView";
import SettingsProfileFieldsEdit from "@/components/SettingsProfileFieldsEdit";
import SettingsLabelsEdit from "@/components/SettingsLabelsEdit";

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        { path: '/', component: HomePage },
        { path: '/login', component: LoginPage },
        { path: '/sso', component: SSO },
        { path: '/logout', component: LoginPage },
        { path: '/urgenten', component: UrgentenView },
        { path: '/urgenten/new', component: UrgentenNew },
        { path: '/urgenten/:id', component: UrgentenEdit },
        { path: '/accounts', component: AccountView },
        { path: '/accounts/:id', component: AccountEdit },
        { path: '/claims', component: ClaimsView },
        { path: '/claims/:id', component: ClaimsEdit },
        { path: '/rapportages', component: ReportsView },
        { path: '/settings', component: SettingsView },
        { path: '/settings/profilefields/:id', component: SettingsProfileFieldsEdit },
        { path: '/settings/labels/:id', component: SettingsLabelsEdit },

        // otherwise redirect to home
        { path: '*', redirect: '/' }
    ]
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login','/sso'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
    if (authRequired && !loggedIn) {
        return next('/login');
    }
    next();
});