<template>
  <div id="inner">
    <div class="row">
      <div class="col-12 text-center">
          <template>
            <DashChartLabels ref="bar"/>
          </template>
<!--        <img src="../assets/img/toegewezen-urgenten.svg">-->
      </div>
    </div>
    <div class="row">
      <div class="col-6">
<!--        <template>
          <Doughnut ref="doughnut"/>
        </template>-->
        <img src="../assets/img/per-corporatie.svg">
      </div>
      <div class="col-6">
        <img src="../assets/img/prestatieafspraken.svg">
      </div>
    </div>
  </div>
</template>

<script>

/*import Doughnut from '/src/components/DonutDashChart';*/
import DashChartLabels from '/src/components/DashChartLabels'

export default {
  name: 'Home',
  components: { DashChartLabels/*,Doughnut*/ }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
