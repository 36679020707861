<template>
    <div id="inner" class="extra-space">
        <div v-if="!finished">
            <h1 class="text-center">
                Bezig met laden
            </h1>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-12">
                    <h1>
                        Account {{status}}
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12 spacer">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b-form @submit.prevent="handleSubmit">
                        <b-form-group
                            id="name-group"
                            label="Naam"
                            label-for="name">
                            <b-form-input
                                id="name"
                                v-model="form.name"
                                type="text"
                                required/>
                        </b-form-group>
                        <b-form-group
                                id="email-group"
                                label="E-mail adres"
                                label-for="email"
                        >
                            <b-form-input
                                    id="email"
                                    v-model="form.email"
                                    type="email"
                                    required>
                            </b-form-input>
                        </b-form-group>
                        <b-form-group v-if="isNew"
                                id="password-group"
                                label="Wachtwoord"
                                label-for="password"
                        >
                            <b-form-input
                                    id="password"
                                    v-model="form.password"
                                    type="password"
                                    minlength="8"
                                    autocomplete="off"
                                    required>
                            </b-form-input>
                        </b-form-group>
                        <b-form-group
                                id="role-group"
                                label="Rol"
                                label-for="role"
                        >
                            <b-form-select
                                    v-model="form.role"
                                    required
                            >
                                <b-form-select-option v-for="item in roles" :key="item.id" :value="item.id">
                                    {{item.name}}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        <b-button class="float-right" type="submit" variant="primary">Opslaan</b-button>
                    </b-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {accountService} from "@/_services";
    import validator from "validator/es";

    export default {
        name: 'AccountView',
        data: () => {
            return {
                finished: false,
                status: 'Aanpassen',
                isNew: 0,
                form: {
                    name: '',
                    email: '',
                    role: '',
                    password: ''
                },
                roles: []
            }
        },
        methods: {
            handleSubmit() {
                if(this.isNew) {
                    accountService.post(this.form.name, this.form.email, this.form.role, this.form.password).then(() => {
                        this.$router.push('/accounts');
                    });
                } else {
                    accountService.put(this.form.name, this.form.email, this.form.role, this.$route.params.id).then(() => {
                        this.$router.push('/accounts');
                    });
                }
            },
            async loadAccount() {
                if(!validator.isUUID(this.$route.params.id)) {
                    if(this.$route.params.id === 'new') {
                        this.status = 'Aanmaken';
                        this.isNew = 1;
                        accountService.getRoles().then((data) => {
                            this.roles = data;
                        })
                        this.finished = 1;
                    } else {
                        this.$router.push('/accounts')
                    }
                } else {
                    accountService.get(this.$route.params.id).then((data) => {
                        if(data.id.length === 0) {
                            this.$router.push('/accounts')
                        } else {
                            this.form.name = data.name;
                            this.form.email = data.email;
                            this.form.role = data.role.id;
                            accountService.getRoles().then((data) => {
                                this.roles = data;
                            })
                            this.finished = true;
                        }
                    })
                }


            }
        },
        async mounted() {
            await this.loadAccount();
        }
    }
</script>

<style scoped>

</style>
