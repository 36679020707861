<template>
    <div id="inner" class="extra-space">
        <div v-if="!finished">
            <h1 class="text-center">
                Bezig met laden
            </h1>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-11">
                    <h1>
                        Claim: {{data.dossier}}
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-11 spacer">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-11 urgent-details">
                    <div class="row">
                        <div class="col-12">
                            <h3>
                                Procedure
                            </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Beoogde Woning
                        </div>
                        <div class="col-9">
                            {{data.secure.address}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Start Procedure
                        </div>
                        <div class="col-9">
                            {{data.status[0].regdate}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h3>
                                Persoonsgegevens
                            </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Naam:
                        </div>
                        <div class="col-9">
                            {{data.secure.letters}} {{data.secure.lastname}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Geboortedatum:
                        </div>
                        <div class="col-9">
                            {{data.secure.dob}}  ({{data.age}})
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Huidige Woonplaats:
                        </div>
                        <div class="col-9">
                            <span v-if="data.secure.zipcode.length">
                                {{data.secure.zipcode}} / {{data.secure.housenumber}}
                            </span>
                            <span v-else>
                                Onbekend
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Gezinssamenstelling:
                        </div>
                        <div class="col-9">
                            {{data.adults}} volwassenen, {{data.children}} kinderen
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Inkomen:
                        </div>
                        <div class="col-9">
                            {{data.income}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Contactgegevens:
                        </div>
                        <div class="col-9">
                            <ul class="list-unstyled">
                                <li>
                                    Telefoon: {{data.secure.phone}}
                                </li>
                                <li>
                                    Email: {{data.secure.email}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h3>
                                Urgentieprofiel
                            </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Label:
                        </div>
                        <div class="col-9">
                            {{data.label.name}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Mega Urgent:
                        </div>
                        <div class="col-9">
                            {{data.mega ? 'Ja' : 'Nee'}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Urgent sinds:
                        </div>
                        <div class="col-9">
                            {{data.regdate}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Maximale Woonlasten:
                        </div>
                        <div class="col-9">
                            {{data.fitting.max}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Minimaal aantal kamers:
                        </div>
                        <div class="col-9">
                            {{data.rooms}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Afkomstig van:
                        </div>
                        <div class="col-9">
                            {{data.notes}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            Vereisten:
                        </div>
                        <div class="col-9">
                            <ul>
                                <li v-for="item in data.profilefields" :key="item.key">
                                    {{item.name}}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="row d-print-none">
                        <div class="col-12 group-buttons">
                            <button class="btn btn-primary float-right" v-on:click="finish">Afhandelen</button>
                            <button class="btn btn-primary float-right" v-on:click="rescind">Aanbieding Annuleren</button>
                            <button class="btn btn-primary float-right" v-on:click="refuse">Weigering door Urgent</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal
                id="finish_modal"
                title="Dossier Afhandelen"
                @ok="process_finish"
        >
            <p>
                U staat op het punt om dit dossier af te handelen.
            </p>
            <p>
                Een op deze manier verwerkte urgent telt mee voor uw totalen.
            </p>
            <p>
                Doe dit alleen als u de urgent daadwerkelijk heeft gehuisvest.
            </p>
        </b-modal>

        <b-modal
                id="rescind_modal"
                title="Dossier vrijgeven"
                @ok="process_rescind"
        >
            <p>
                U staat op het punt om dit dossier vrij te geven.
            </p>
            <p>
                Deze urgent telt niet mee voor uw totalen.
            </p>
            <p>
                Doe dit alleen als de urgent niet gehuisvest kan worden door een besluit van uw corporatie.
            </p>
        </b-modal>

        <b-modal
                id="refuse_modal"
                title="Urgent heeft geweigerd"
                @ok="process_refuse"
        >
            <p>
                U staat op het punt om dit dossier af te handelen als <strong>weigering door de urgent.</strong>
            </p>
            <p>
                Een op deze manier verwerkte urgent telt mee in uw aantal verwerkte urgenten.
            </p>
            <p>
                De urgent wordt hierna uit het systeem verwijderd en verliest hiermee urgentie. Doe dit alleen als de urgent uw woning zonder goede reden geweigerd heeft.
            </p>
        </b-modal>
    </div>


</template>

<script>
    import {claimService, urgentsService} from "@/_services";
    import validator from "validator/es";

    export default {
        name: 'ClaimsView',
        data: () => {
            return {
                finished: false,
                data: {}
            }
        },
        methods: {
            loadUrgent() {
                if(!validator.isUUID(this.$route.params.id)) {
                    this.$router.push('/urgenten')
                } else {
                    urgentsService.get(this.$route.params.id).then((data) => {
                        this.data = data;
                        this.finished = true;
                    });

                }
            },
            finish() {
                this.$bvModal.show('finish_modal');
            },
            process_finish() {
                claimService.finish(this.$route.params.id).then(()=>{
                    this.$router.push('/claims');
                })
            },
            rescind() {
                this.$bvModal.show('rescind_modal');
            },
            process_rescind() {
                claimService.rescind(this.$route.params.id).then(()=>{
                    this.$router.push('/urgenten');
                })
            },
            refuse() {
                this.$bvModal.show('refuse_modal');
            },
            process_refuse() {
                claimService.refuse(this.$route.params.id).then(()=>{
                    this.$router.push('/claims');
                })
            },
        },
        async mounted() {
            this.loadUrgent();
        }
    }

</script>

<style scoped>

</style>
